import { BridgeComponent, BridgeElement } from '@hotwired/strada';

export default class extends BridgeComponent {
  static component = 'form'
  static targets = ['submit']

  connect() {
    super.connect()
    this.notifyBridgeOfConnect()
  }

  notifyBridgeOfConnect() {
    const submitButton = new BridgeElement(this.submitTarget)
    const submitTitle = submitButton.title

    this.send('connect', { submitTitle }, () => {
      this.submitTarget.click()
    })
  }

  submitStart(event) {
    this.disableSubmitButton()
  }

  submitEnd(event) {
    this.enableSubmitButton()
  }

  imageEditorUploadStart(event) {
    this.disableSubmitButton()
  }

  imageEditorUploadEnd(event) {
    this.enableSubmitButton()
  }

  enableSubmitButton() {
    this.submitTarget.disabled = false
    this.send('submitEnabled')
  }

  disableSubmitButton() {
    this.submitTarget.disabled = true
    this.send('submitDisabled')
  }

}
