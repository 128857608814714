import ApplicationController from '../application_controller';
import ReconnectingWebSocket from "reconnecting-websocket";

export default class extends ApplicationController {
  static values = {
    url: String,
    env: String,
    connected: Boolean
  };

  async initialize() {
    console.log("initialising cast hub");
    if (!this.castSocket) {
      const webSocketProtocol =
        this.envValue === "development" ? "ws" : "wss";
      const hubUrl = `${webSocketProtocol}:${this.urlValue}`;
      this.castSocket = new ReconnectingWebSocket(hubUrl);
      this.castSocket.onopen = ({ target }) => {
        console.log("[wss-cast] Connection established");
        this.connectedValue = true;
        this.dispatch('connected');
      };
      this.castSocket.onmessage = (e) => {
        const payload = JSON.parse(e.data);
        switch (payload.type) {
          case "broadcast:start":
            console.log(`[wss-cast] ${payload.type}`, payload);
            this.dispatch('broadcastStarted', {detail: {broadcast_uid: payload.broadcast_uid}});
            break;
          default:
            console.log(`[wss-cast] Unknown message type: ${payload.type}`);
        }
      };
      this.castSocket.onclose = (event) => {
        this.connectedValue = false;
        if (event.wasClean) {
          console.log(
            `[wss-cast] Connection closed cleanly, code=${event.code} reason=${event.reason}`
          );
        } else {
          // e.g. server process killed or network down
          // event.code is usually 1006 in this case
          console.log("[wss-cast] Connection died");
        }
        this.dispatch('disconnected');
      };
    }
  }

  send({ detail: { messageType, data } }) {
    console.log('[wss-cast] - send', messageType, this.castSocket);
    if (!this.castSocket) return;
    this.castSocket.send(`${messageType}:${JSON.stringify(data)}`);
  }

  payloadSend({ detail: { payload } }) {
    console.log('[wss-cast] - payloadSend', payload.size, this.castSocket);
    if (!this.castSocket) return;
    this.castSocket.send(payload);
  }

  networkStatus({ detail: { status } }) {
    if (!this.castSocket) return;
    console.log('[wss-cast] - networkStatus - online: ', status.onLine);
    console.log('[wss-cast] - networkStatus - ws connected: ', this.connectedValue);
    if (status.onLine) {
      if (this.connectedValue) return;
      this.castSocket.reconnect();
    } else {
      this.castSocket.close();
    }
  }
}
